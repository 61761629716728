import React, { useContext, useEffect, useMemo, useState } from "react"; // from './logo.svg';
import axios from "axios";
// import { ChevronLeft } from "react-bootstrap-icons";
// import Carousel from "react-bootstrap/Carousel";
// import { Spinner } from "react-bootstrap";
import Slider from "react-slick";
import moment from "moment";
// import "slick-carousel";
// import "~slick-carousel/slick/slick-theme.css";

import "./App.css";
import "./arrow_back_ios_black_24dp.svg";

function App() {
  // give me the first parameter of the URL
  const ticketIDs = new URL(window.location);
  const ticket_url_ID = ticketIDs.pathname.split("/")[1];

  // alert(ticketIDs.pathname.split("/")[1]);

  const [getBarcodeBool, setBarcodeBool] = useState({ bool: false });
  const [pseLoaded, setPseLoaded] = useState(false);
  const [ticketObj, setticketObj] = useState({});
  // const [ticketObj, setticketObj] = useState({});

  useEffect(() => {
    // alert("no barcodeID");
    if (ticketObj.barcodeID === undefined) {
      axios.get(`/api/${ticket_url_ID}`).then((res) => {
        // console.log(res.data);
        // alert(res.data);
        setticketObj(res.data);
      });
    }
    if (false) {
      // axios.get('/')
      const ticketIDs = new URL(window.location).hash.substring(1);
      // alert(ticketIDs)
      const fetchURL = `https://api.tixbc.com/tmbc/ticket/${ticketIDs}`;
      // alert(fetchURL)
      axios.get(fetchURL).then((res) => {
        // alert(res.data)
        setticketObj(res.data);
      });
    }
    // // console.log(ticketIDs.split(','))
    // const script = document.createElement("script");

    // script.src = "/pse.js";
    // script.async = true;

    // document.body.appendChild(script);

    setTimeout(() => {
      setPseLoaded(true);
    }, 1000);
    return () => {
      // document.body.removeChild(script);
    };
  }, []);

  console.log(ticketObj);
  if (ticket_url_ID === "" || ticketObj.barcodeID === undefined) {
    return (
      <div>
        <h1>.</h1>
      </div>
    );
  }

  // console.log('aa',)

  const settings = {
    dots: true,
    // infinite: true,
    speed: 500,
    centerMode: true,
    centerPadding: "60px",
    className: "center",
    // slidesToShow: 3,
    appendDots: (dots) => {
      // console.log(dots)
      return (
        <div
          style={
            {
              // backgroundColor: "#ddd",
              // borderRadius: "10px",
              // padding: "10px"
            }
          }
        >
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      );
    },
  };

  const backArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#FFFFFF"
    >
      <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
      <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
    </svg>
  );

  // // working code for showing a barcode
  // const rr = () => {
  //   const t = ticketObj.jsonObj.ticketBCData.tokenMap["711690835226716052"];
  //   alert(t.barcode);
  //   setTimeout(() => {
  //     var r = new window.Presence.SecureEntryView();
  //     return (
  //       // r.setPDF417Subtitle(
  //       //   e.props.intl.formatMessage({
  //       //     id: "order-event-ticket-barcode",
  //       //   })
  //       // ),
  //       r.setSelector("#barcode-container-" + 0), r.setToken(t.barcode) //, r
  //     );
  //   }, 1000);
  // };

  // rr();

  // return (
  //   <>
  //     {true && (
  //       <div
  //         key={"barcode-container-0"}
  //         id="barcode-container-0"
  //         style={{ width: "216px", height: "160px" }}
  //       />
  //     )}
  //   </>
  // );

  const eventName = ticketObj.jsonObj.eventDetailsData.items[0].name;
  const eventDate =
    ticketObj.jsonObj.eventDetailsData.items[0].dateRange.startDate;
  const eventDateFormatted = moment(eventDate).format("ddd • MMM D • h:mm A");
  const venueName = ticketObj.jsonObj.eventDetailsData.items[0].venue.name;
  const tickets = [
    ticketObj.jsonObj.eventDetailsData.items[0].tickets.find((t) => t.barcode),
  ];

  return (
    <>
      <div className="App">
        <div className="">
          <div
            className="tixbcHeader"
            style={{
              // paddingLeft: '24px',
              // marginLeft: '24px',
              // marginRight: '24px',
              margin: "auto",
              backgroundColor: "#009cde",
            }}
          >
            <div className="tixbcBackIcon">{backArrow}</div>
            <div className="tixbcHeaderGameDetails">
              <div style={{}}>
                <div className="tixbcHeaderGameDetailsGameName">
                  {eventName}
                </div>
                <div className="tixbcHeaderGameDetailsLoc">
                  {eventDateFormatted} - {venueName}
                </div>
              </div>
            </div>
          </div>
          <div className="tixbcTicketBody">
            <div>
              <Slider {...settings}>
                {tickets.map((row, index) => {
                  const ticket = row;
                  const barcodeID = ticket.barcode;
                  const barcodeToken =
                    ticketObj.jsonObj.ticketBCData.tokenMap[barcodeID].barcode;

                  return (
                    <div className="tixbcTicketBody1">
                      <div
                        style={{
                          // backgroundColor: row.vipColor || ticketObj.c1,
                          text: "white",
                          // width: '500px',
                          marginLeft: "auto",
                          marginRight: "auto",
                          // paddingRight: '10px',
                          // paddingLeft: '10px',
                        }}
                      >
                        <div
                          className={"ticketHeaderTop"}
                          style={{
                            color: "white",
                            backgroundColor: "#0150a7",
                          }}
                        >
                          <span>
                            {console.log(row.ticketType.name)}
                            {row.ticketType.name || row.ticketName}
                          </span>
                          <div style={{ float: "right" }}>
                            <svg
                              width="20"
                              height="20"
                              className="infoIcon"
                              aria-label="View Details"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <desc>Created with Sketch.</desc>
                              <g
                                id="Icons/Indicators/InfoDot-Outline-24"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <path
                                  d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,7.25 C12.5522847,7.25 13,6.80228475 13,6.25 C13,5.69771525 12.5522847,5.25 12,5.25 C11.4477153,5.25 11,5.69771525 11,6.25 C11,6.80228475 11.4477153,7.25 12,7.25 Z M12,18 L12,18 C12.5522847,18 13,17.5522847 13,17 L13,10 C13,9.44771525 12.5522847,9 12,9 L12,9 C11.4477153,9 11,9.44771525 11,10 L11,17 C11,17.5522847 11.4477153,18 12,18 Z"
                                  id="info"
                                  fill="#262626"
                                  fill-rule="nonzero"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={"ticketHeaderLower"}
                          style={{
                            color: "white",
                            display: "flex",
                            backgroundColor: "#026cdf",
                            width: "100%",
                            left: "-20",
                          }}
                        >
                          {row.location.generalAdmission && (
                            <span>General Admission</span>
                          )}
                          {row.location.section}
                          {/* <div className={"rowDetails"}>
                              <div>Sec</div>
                              <div>{"row.section"}</div>
                            </div>
                            <div className={"rowDetails"}>
                              <div>Row</div>
                              <div>{"row.row"}</div>
                            </div>
                            <div className={"rowDetails"}>
                              <div>Seat</div>
                              <div>{"row.seat"}</div>
                            </div> */}
                        </div>
                        <div style={{ color: "white" }}>
                          {/*{console.log(row)}*/}
                          {"row.gatePortalLabel"}
                        </div>
                      </div>
                      <>
                        {/*<span>Section: {row.section} Row: {row.row} Seat: {row.seat}</span>*/}
                      </>
                      <BarCode ticketIndex={index} ticketObj={barcodeToken} />
                    </div>
                  );
                })}
                {/*<div className="tixbcTicketBody1">a section</div>*/}
                {/*<div className="tixbcTicketBody1">b section</div>*/}
                {/*<div className="tixbcTicketBody1">c section</div>*/}
              </Slider>
            </div>
          </div>
          <div className={"tmdbFooter"}>
            <button
              disabled=""
              className="sc-bdfBQB hzxSPn base-button__BaseButton-inDDeo kLTquS noButtons teamColorRingEffect  noFocus"
              id="transferButton"
              style={{ background: ticketObj.c1 }}
            >
              Transfer
            </button>
            <button
              disabled=""
              className="sc-bdfBQB hzxSPn base-button__BaseButton-inDDeo kLTquS noButtons teamColorRingEffect  noFocus"
              id="sellButton"
              style={{ background: ticketObj.c1 }}
            >
              Sell
            </button>
            <div style={{ background: ticketObj.c1 }} className={"meatballbox"}>
              ...
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function BarCode({ ticketIndex, ticketObj }) {
  let render;

  const [showBarCodeButton, setshowBarCodeButton] = useState({ show: true });
  const loadBarcode = () => {
    setshowBarCodeButton({ show: false });

    render = new window.Presence.SecureEntryView();
    setshowBarCodeButton({ show: false });
    return (
      render.setSelector(`#barcode-container-${ticketIndex}`),
      render.setToken(ticketObj),
      render
    );
  };

  useEffect(() => {
    if (showBarCodeButton.show) {
      // setInterval(() => {
      loadBarcode();
      // make only one div within barcode-container-0
      const barcodeContainer = document.querySelector(
        `#barcode-container-${ticketIndex}`
      );
      if (barcodeContainer.children.length > 1) {
        for (let i = 1; i < barcodeContainer.children.length; i++) {
          barcodeContainer.removeChild(barcodeContainer.children[i]);
        }
      }

      // }, 1000 * 5);
    }
  }, [showBarCodeButton]);

  //   var e = new window.Presence.SecureEntryView({});
  //   render = e;

  //   console.log(ticketObj);
  //   e.setToken(ticketObj);
  //   e.setSelector(`#barcode-inner${ticketIndex}`);
  // };
  // useEffect(() => {
  //   // only run if document.querySelector("#barcode-inner0") is not null
  //   console.log(document.querySelectorAll("#barcode-inner0").length);
  //   if (document.querySelectorAll("#barcode-inner0").length === 1) {
  //     loadBarcode();
  //   }
  // }, []);

  // return <div id={`barcode-container-${ticketIndex}`} />;

  return (
    <div data-v-0140e88e="" className="v2-ticket-body">
      <div data-v-0140e88e="" className="barcode-main-container">
        <div
          data-v-0140e88e=""
          className="barcode-inner-container"
          on={() => alert(`page ${ticketIndex}`)}
        >
          <div data-v-0140e88e="" id="barcode-here">
            <div
              data-v-0140e88e=""
              id={`barcode-container-${ticketIndex}`}
              style={{ width: "100px", height: "100px" }}
            >
              {/* {render} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
